import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
} from "../components/utils/storyblok";
import Seo from "../components/layout/seo";
import {
  getPageSlug,
  prefixUrl,
  suffixTitle,
} from "../components/utils/no-import-commons";
import { LanguageContext } from "../components/context/language-context";

const Page = ({ data, pageContext, location }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }
  enrichStoryWithGatsbyImages(story, data?.images?.nodes ?? []);

  // on page mount we send form-event for TYP coming from ads on old unbounce
  // gimmick specific to the /grazie-contatti page
  useEffect(() => {
    if (location.pathname === "/grazie-contatti" && window?.dataLayer) {
      window.dataLayer.push({
        event: "form-submission",
        formData: {
          "tipo-form": "landing",
          pagina: location.pathname,
          termini: true,
        },
      });
    }
  }, []);

  return (
    <Layout lm={pageContext.lm}>
      {(story?.content?.content ?? []).map(getBlok)}
    </Layout>
  );
};

export const Head = ({ data, pageContext }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  const seoInfo = story?.content?.page_information[0];
  seoInfo.url = prefixUrl(getPageSlug(seoInfo.slug, story.lang));
  seoInfo.title = suffixTitle(seoInfo?.title ?? "");
  const lm = { ...(pageContext?.lm ?? {}) };
  for (const k in lm) {
    lm[k] = prefixUrl(lm[k]);
  }

  return <Seo info={seoInfo} lm={lm} />;
};

export const query = graphql`
  query ($id: String!, $images: [String]!) {
    storyblokEntry(id: { eq: $id }) {
      field_component
      content
      lang
      name
      slug
    }
    images: allFile(filter: { base: { in: $images } }) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Page;
